import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import ContactLink from '../../micro-components/contact-link'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Ercole in Tebe',
    desc =
      `Riprese audio e video dell'opera Ercole in Tebe, eseguita dopo trecentosessant'anni dall'inaugurazione del Teatro della Pergola, a Firenze.`,
    subtitle = 'TGR Rai',
    roles = ['Videomaker', 'Tecnico del suono'],
    videoSrc = [
      'https://www.youtube.com/watch?v=7nHPVCUH7ok',
      'https://www.youtube.com/watch?v=wDM5DEFPVd0',
      'https://www.youtube.com/watch?v=dUABrbsH3Zg&t=19s',
    ],
    // put this TRUE only if there are logos to show
    logo = true

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc}/>
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-ercole-tebe.jpg'
        alt='Cover per il lavoro Ercole in Tebe'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>9 febbraio 2022 - Teatro della Pergola, Firenze.</span>
        <span>
          “Ercole in Tebe” di Jacopo Melani e libretto di Andrea Moniglia, 1661.
        </span>
        <span>
          Prima esecuzione in tempi moderni a trecentosessanta anni
          dall'inaugurazione pubblica del teatro.
        </span>
        <span>
          Trascrizione di <em>Dimitri Betti</em> per{' '}
          <ContactLink
            to='https://musicidelgranprincipe.it'
            external={true}
            type='none'
          >
            I Musici del Gran Principe
          </ContactLink>
          .
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2 margin-bottom'>
        <span>
          <em> “Ercole in Tebe” è tornato alla Pergola</em> di{' '}
          <em>Francesco Tei</em>.
        </span>
        <span>Riprese di Giordano Betti.</span>
      </p>
      {/* DESC n.3 */}
      <p className='work-description desc-3 margin-bottom'>
        <span>
          A trecentosessanta anni di distanza torna alla luce l'
          <em>Ercole in Tebe </em>
          di Giovanni Andrea Moniglia e Jacopo Melani, la monumentale festa
          teatrale che inaugurò il <em>Teatro Della Pergola di Firenze</em> in
          occasione delle nozze medicee tra Cosimo III e Marguerite Louise
          d'Orléans.
        </span>
      </p>
      {/* ADD HERE ALL THE LOGOS */}
      <div className='logo-container'>
        <StaticImage
          src='../../images/logo/logo-teatro-toscana.png'
          alt='Logo Teatro della Toscana'
          className='work-logo-big logo-4'
        />
        <StaticImage
          src='../../images/logo/logo-rai.png'
          alt='Logo RAI'
          className='work-logo-small logo-1'
        />
        <StaticImage
          src='../../images/logo/logo-firenze.png'
          alt='Logo Comune di Firenze'
          className='work-logo-mid logo-3'
        />
        <StaticImage
          src='../../images/logo/logo-medici.png'
          alt='Logo Museo de Medici'
          className='work-logo-big logo-2'
        />
        <StaticImage
          src='../../images/logo/logo-institut-francais.png'
          alt='Logo Institut Francais'
          className='work-logo-big logo-5'
        />
      </div>
    </WorkLayout>
  )
}

export default Work
